<template>
  <PageHeader :title="title" />
  
  <tablecustom 
    @search="searchB" 
    :dataParams="dataParams"
    :objParams="objParams"
    :columns="columns" 
    :rows="rows" 
    :pages="objPages"
    :showpag="true" 
    @getdata="getdata"
    @changelimit="changelimit"
    @changeFilter="changeFilter" 
    @clearFilter="clearFilter" 
    @open="open"
  />

  <viewbox 
    v-if="modalShowview === true" 
    @close="modalShowview = false" 
    :obj="this.obj" 
  />

</template>

<script>
import PageHeader from "@/components/page-header";
import infoblocks from '@/components/info/blocks'
import tablecustom from '@/components/globaltable/index';
import viewbox from '@/views/projects/view/index.vue'
import { Archive } from '@/API.js';
import { storeS } from "@/store";
import { mutateStatusProject, mutatePriorityTicket } from '@/usabilityScripts/globalMutate.js'

let apiArchive = new Archive();

export default {
  components: {
    PageHeader,
    tablecustom,
    infoblocks,
    viewbox
  },
  data() {
    return {
      title: "archiveProjects",
      modalShowview: false,
      objPages: {},
      dataParams: {
          status: true,
          page: "projectsArchive"
      },
      objParams:{
          page: '1',
          pagelimit: '10',
          search: '',
          statusArchive: '',
          confStatus: '',
          workerId: '',
          pr1: '',
          pr2: '',
          teamlead: '',
      },
      columns: [
        {
          name: this.$t("Point_name"),
          text: "projectName",
          align: "right",
          status: true
        },
        {
          name: this.$t("Status"),
          text: 'status',
          align: "left",
          status: true,
          mutate: (item) => this.mutateStatus(item).name,
          mutateClass: (item) => this.mutateStatus(item).color
        },
        {
          name: this.$t("priority"),
          text: 'priority',
          align: "left",
          status: true,
          mutate: (item) => this.mutatePriority(item).name,
          mutateClass: (item) => this.mutatePriority(item).color
        },
        {
          name: this.$t("Categories"),
          text: "category",
          align: "right",
          status: true,
          mutate: (item) => item == ''? this.$t('Null') : item
        },
      ],
      rows: [],
    };
  },
  created() {
    this.getdata();
  },
  methods: {
    clearFilter() {
      this.objParams = {
          page: '1',
          pagelimit: '10',
          search: '',
          statusArchive: '',
          confStatus: '',
          workerId: '',
          pr1: '',
          pr2: '',
          teamlead: '',
      }
      this.getdata()
    },
    getdata(page){
      this.objParams.page = page != undefined ? page : '1';
      apiArchive.getAllArchiveProjects(this.objParams).then(result => {
        if(result.status == 'done'){
          this.objPages = result.data;
          this.rows = result.data.items;
        }
      })
    },
    open(e){
      apiArchive.getArchiveProject(e.projectId).then(res =>{
        if(res.status === 'done'){
          this.obj = res.data
          this.modalShowview = true
        }
      })
    },
    changelimit: function(limit){
        this.objParams.pagelimit = limit;
        this.getdata();
    },
    searchB(e){
      this.objParams.search = e
      this.getdata();
    },
    changeFilter(name, value){
      console.log(name, value)
    },
    mutatePriority(e){
      return mutatePriorityTicket(e)
    },
    mutateStatus(e){
      return mutateStatusProject(e)
    },
  },
  computed: {
    user() {
      return storeS.userbase
    },
    perms() {
      return storeS.perms
    },
    check() {
      return storeS.settingcheck
    },
  },
};
</script>

<style scoped>
  .tr_etner:hover {
  cursor: pointer;
  background: #f3f3f9;
  }
  .howto_block {
  margin-left:10px;
  }
  .howto_block i {
  font-size: 23px;
  opacity: 0.5;
  }
  .howto_block i:hover{
  opacity: 1;
  }

  .howto_block {
  position: relative;
  display: inline-block;
  }

  .howto_block .tooltiptext {
  visibility: hidden;
  position: absolute;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  padding: 10px 0;
  border-radius: 6px;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s;
  margin-left: 10px;
  }

  .howto_block .tooltiptext::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: -7px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent #555 transparent transparent;
  }

  .howto_block:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
  }
</style>
